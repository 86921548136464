import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    standalone: false
})
export class AvatarComponent implements OnInit {
    @Input() data: string;
    @Input() small: boolean;

    constructor () { }

    ngOnInit (): void {
        // console.log(this.data);
    }
}
