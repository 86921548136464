import { OnChanges, Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[ng-focus]',
    standalone: false
})
export class AutofocusDirective implements OnChanges {
    @Input() public appAutoFocus: boolean;

    constructor (private el: ElementRef) {
    }

    ngOnChanges (ev: SimpleChanges) {
        if (ev['appAutoFocus'] && this.appAutoFocus) {
            this.el.nativeElement.focus();
            if (this.el.nativeElement.select) {
                this.el.nativeElement.select();
            }
        }
    }
}
