import { Component, Inject, OnInit, inject, DestroyRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthGuardService } from '../../../services/auth-guard.service';
import { ToolsService } from '../../../services/tools.service';
import { RegistrationService } from '../../../services/registration.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Partner } from '@app/models/partner/IPartner';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
    selector: 'app-newlettermodal',
    templateUrl: './newlettermodal.component.html',
    styleUrls: ['./newlettermodal.component.scss'],
    standalone: false
})
export class NewlettermodalComponent implements OnInit {
    sitekey: string = '';
    registrationsended: boolean = false;
    unregistrationsended: boolean = false;
    loading: boolean = false;
    smallMode: boolean = false;
    registrationform: FormGroup;
    unregistrationform: FormGroup;
    partner: Partner;
    email: string;
    error: string = '';
    logo: string = '';
    destroyRef = inject(DestroyRef);
    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<NewlettermodalComponent>,
        private guardService: AuthGuardService,
        private registrationService: RegistrationService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private toolService: ToolsService,
        private cdr: ChangeDetectorRef,
        protected mediaObserver: BreakpointObserver,
    ) { }

    ngOnInit(): void {
        this.initMedia();
        this.initPartner();
    }


    async initMedia() {

        if (this.mediaObserver.isMatched(Breakpoints.XSmall)) {
            this.smallMode = true;
        }
        if (this.mediaObserver.isMatched(Breakpoints.Small)) {
            this.smallMode = true;
        }
        if (this.mediaObserver.isMatched(Breakpoints.Medium)) {
            this.smallMode = false;
        }
        if (this.mediaObserver.isMatched(Breakpoints.Large)) {
            this.smallMode = false;
        }
        if (this.mediaObserver.isMatched(Breakpoints.XLarge)) {
            this.smallMode = false;
        }

        this.cdr.detectChanges();
    }

    async initPartner() {
        this.guardService.getPartner$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(partner => {
            if (partner?.partnerid?.length > 0) {
                this.partner = partner;
                this.checkLogo();
                this.initForms();
            }
        });
    }


    async initForms() {
        this.sitekey = this.toolService.getcaptchasitekey();
        this.registrationform = this.fb.group({
            captcha: new FormControl(null, [Validators.required, Validators.minLength(2)]),
            salutation: new FormControl(null, [Validators.minLength(2)]),
            firstname: new FormControl(null, [Validators.minLength(2)]),
            lastname: new FormControl(null, [Validators.minLength(2)]),
            email: new FormControl(this.data.email, [Validators.required, Validators.email]),
            check: new FormControl(null, [Validators.required]),
            floatLabel: 'auto',
        });
        this.unregistrationform = this.fb.group({
            captcha: new FormControl(null, [Validators.required, Validators.minLength(2)]),
            email: new FormControl(this.data.email, [Validators.required, Validators.email]),
            floatLabel: 'auto',
        });
        this.cdr.detectChanges();
    }

    async checkLogo() {
        if (this.partner?.logo?.length > 0) {
            this.logo = 'https://' + document.location.hostname + '/logo/' + this.partner.partnerid + '/' + this.partner.logo;
        }
        this.cdr.detectChanges();
    }

    async setChecked() {
        this.registrationform.controls['check'].setValue(!this.registrationform.value.check);
    }

    async handleToken(token) {
        this.registrationform.controls['captcha'].setValue(token);
        if (this.registrationform.valid) {
            this.loading = true;
            this.email = this.registrationform.value.email;
            const newReg = {
                firstname: this.registrationform.value.firstname,
                lastname: this.registrationform.value.lastname,
                captcha: this.registrationform.value.captcha,
                salutation: this.registrationform.value.salutation,
                registration: this.registrationform.value.check,
                email: this.registrationform.value.email,
            };
            this.registrationService.request({ newReg: newReg, partner: this.partner }, 'create').pipe(
                takeUntilDestroyed(this.destroyRef)
            ).subscribe(res => {
                if (res?.error) {
                    this.error = res.error;
                } else {
                    this.registrationsended = true;
                    this.registrationform.reset();
                }
                this.loading = false;
            });
        } else {
            this.registrationform.markAllAsTouched();
        }
    }

    async gotodataschutz(ev) {
        ev.stopPropagation();
        window && window.open('/datenschutz', '_blank', 'noopener');
    }

    async onNoClick() {
        this.dialogRef.close();
    }

    async registrierenF() {
        this.recaptchaV3Service.execute('newregistrationfornewsletter').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((token) => this.handleToken(token));
    }

    async unregistrierenF() {
        this.recaptchaV3Service.execute('unregistrationfornewsletter').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((token) => this.handleTokenforunsub(token));
    }

    handleTokenforunsub(token) {
        this.unregistrationform.controls['captcha'].setValue(token);
        if (this.unregistrationform.valid) {
            this.loading = true;
            this.email = this.unregistrationform.value.email;
            const newReg = {
                captcha: this.unregistrationform.value.captcha,
                email: this.unregistrationform.value.email,
            };
            this.registrationService.request({ newReg: newReg, partner: this.partner }, 'unsubscribefromnewletter').pipe(
                takeUntilDestroyed(this.destroyRef)
            ).subscribe(res => {
                if (res?.error) {
                    this.error = res.error;
                } else {
                    this.unregistrationsended = true;
                    this.unregistrationform.reset();
                }
                this.loading = false;
            });
        } else {
            this.unregistrationform.markAllAsTouched();
        }
    }
}
