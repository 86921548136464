import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@app/services/i18n.service';
import { map } from 'rxjs';

@Pipe({
    name: 'i18n',
    pure: false,
    standalone: false
})
export class I18nPipe implements PipeTransform {
    constructor(public i18nService: I18nService) { }
    transform(phrase: any, args: any = null): any {
        return this
            .i18nService
            .langOb
            .pipe(
                map(lang => this.i18nService.translate(phrase, lang)),
            )
    }
}
