import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ComponentRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'clever-rightmodal',
    templateUrl: './rightmodal.component.html',
    styleUrls: ['./rightmodal.component.scss'],
    animations: [
        trigger('modalState', [
            transition(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('150ms', style({ transform: 'translateX(0%)' })),
            ])
        ])
    ],
    standalone: false
})
export class RightmodalComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Output() triggerCloseModal: EventEmitter<boolean> = new EventEmitter();
    @Input() cmpRef: ComponentRef<any>;
    @Input() type: string;
    @Input() id: string;

    @ViewChild('target', {
        read: ViewContainerRef
    }) target: ViewContainerRef;

    private isViewInitialized: boolean = false;

    constructor(
        protected render: Renderer2
    ) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.render.addClass(document.body, 'model-open');
        });
    }

    ngOnDestroy(): void {
        this.render.removeClass(document.body, 'model-open');
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
    }

    ngOnChanges(): void {
        this.updateComponent();
    }

    ngAfterViewInit(): void {
        this.isViewInitialized = true;
        this.updateComponent();
    }

    closeModal() {
        this.render.removeClass(document.body, 'model-open');
        this.triggerCloseModal.emit(true);
    }

    back(ev) {
        ev.stopPropagation();
        this.closeModal();
    }

    updateComponent() {
        if (!this.isViewInitialized) {
            return;
        }
        this.target.insert(this.cmpRef.hostView);
    }
}
